import React from "react";
import { clone } from "@haici/utils";
import { Source, Layer } from "react-map-gl";
// import pathData from "./activity_184953016.gpx.json";

const getProportionData = (array, number = 1) => {
  const proportionIndex = Math.ceil(array.length * number);
  return array.slice(0, proportionIndex);
};

const PathComponent = ({ data }) => {
  // const backConfig = {
  //   id: 'path_back',
  //   type: 'line',
  //   source: 'route',
  //   layout: {
  //     'line-join': 'round',
  //     'line-cap': 'round',
  //   },
  //   paint: {
  //     'line-color': 'rgba(175, 175, 175, .6)',
  //     'line-width': 10,
  //   },
  // };
  // const pathConfig = {
  //   id: 'path',
  //   type: 'line',
  //   source: 'route',
  //   layout: {
  //     'line-join': 'round',
  //     'line-cap': 'round',
  //   },
  //   paint: {
  //     'line-color': '#80b955',
  //     'line-width': 8,
  //   },
  // };
  const borderConfig = {
    id: "border",
    type: "line",
    // source: 'lines1',
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 10,
    },
  };

  const allPathData = clone(data);
  // console.log("local data", pathData);
  // const allPathData = clone(pathData);

  const showPathData = clone(allPathData.features[0]);

  // showPathData.geometry.coordinates[0] = getProportionData(
  //   showPathData.geometry.coordinates[0],
  //   0.5,
  // );

  // allPathData.features.push(clone(showPathData));
  // allPathData.features.push(clone(showPathData));
  // allPathData.features.unshift(showPathData);
  // allPathData.features.unshift(showPathData);

  // allPathData.features[0].properties = {
  //   width: 10,
  //   color: "rgba(255,255,255, .8)",
  // };
  allPathData.features[0].properties = { width: 6, color: "#3CD87A" };
  // allPathData.features[1].properties = { width: 8, color: "#80b955" };
  // allPathData.features[1].properties = { width: 10, color: '#000' };
  // allPathData.features[2].properties = { width: 8, color: "#80b955" };
  // allPathData.features[1].properties = { width: 10, color: '#000' };

  const backConfig = {
    id: "path_back",
    type: "line",
    source: "lines",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      "line-round-limit": 1,
    },
    paint: {
      "line-width": ["get", "width"],
      "line-color": ["get", "color"],
    },
  };
  return (
    <>
      <Source type="geojson" data={allPathData}>
        <Layer {...backConfig} />
        {/* <Layer {...pathConfig} /> */}
        {/* <Layer {...borderConfig} /> */}
        {/* <Layer {...pathConfig} /> */}
      </Source>
    </>
  );
};

export default PathComponent;
