import { useEffect, useState } from "react";
import geojsonExtent from "@mapbox/geojson-extent";
import { get } from "@/utils/request";
// import { useMap } from "react-map-gl";

export const useGetPathData = (geoPath, map) => {
  const [geoData, setGeoData] = useState(null);
  const getPathData = async () => {
    const resData = await get(geoPath, {}, { noCode: true });
    setGeoData(resData);
    // const [lon, lat] = polylabel([resData.features[0].geometry.coordinates], 1);
    const bounds = geojsonExtent(resData);
    map.fitBounds(bounds, {
      padding: {
        top: 120,
        bottom: 120,
        left: -600,
        right: 120,
      },
    });
    // map.flyTo({
    //   center: [lon + 0.018, lat],
    //   zoom: 12.9,
    // });
  };
  useEffect(() => {
    if (!map) {
      return;
    }
    if (geoPath) {
      getPathData().then();
    } else {
      setGeoData(null);
    }
  }, [map, geoPath]);
  return {
    geoData,
  };
};
