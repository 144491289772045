import qt from "query-string";
import { mergeDeep } from "@haici/utils";

export const request = (url, options = {}) => {
  // const authObj = JSON.parse(
  //   localStorage.getItem("supabase.auth.token") || "{}"
  // );
  //
  // const newOptions = mergeDeep(options, {
  //   headers: {
  //     "x-token": authObj?.currentSession?.access_token,
  //   },
  // });

  return fetch(url, options)
    .then((res) => res.json())
    .then((res) => {
      if (options.noCode) {
        return res;
      }
      if (res.code === 0) {
        return res.data;
      }
      throw new Error(res.message);
    });
};

export const get = (url, data = {}, options = {}) => {
  let getUrl = url;
  if (Object.keys(data).length) {
    getUrl = `${url}?${qt.stringify(data)}`;
  }
  return request(getUrl, { ...options, method: "get" });
};

export const post = (url, data = {}, options = {}) => {
  const postOptions = {
    ...options,
    method: "post",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      ...(options.headers || {}),
    },
  };
  return request(url, postOptions);
};

export const upload = (url, data, options = {}) => {
  const postOptions = {
    ...options,
    method: "post",
    body: data,
    headers: {
      "Content-Type": "application/octet-stream",
      ...(options.headers || {}),
    },
  };
  return request(url, postOptions);
};
